@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@tailwind base;

@layer base {
  body {
    font-family: Lato, 'Noto Sans TC', PingFangTC-Regular, 'Microsoft JhengHei', sans-serif;
  }
}

@tailwind components;
@tailwind utilities;

